import { NewpayBasketErrorPage, NewpayErrorPage, ZipBasketErrorPage, ZipErrorPage } from 'components-typescript-react';
import React, { useMemo, useRef } from 'react';

import { CAC, InitToken, ProductNames } from '@app-types';
import { CheckoutAuthCertificate } from '@utils';
import { CheckoutAuthCertificateLens } from '@utils/CheckoutAuthCertificate/types';
import { BasketErrors, CACError, DekoWalletCheckoutError } from '@utils/Errors/types';

import GenericErrorPage from './GenericErrorPage';

interface ErrorPageProps {
  token: InitToken | CAC;
  onClose: () => void | Promise<void>;
  error?: DekoWalletCheckoutError;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  token,
  onClose,
  error = [
    {
      product: ProductNames.GENERIC,
      errors: [
        {
          error: CACError.generic,
          info: {
            message: 'something went wrong!',
          },
        },
      ],
    },
  ],
}) => {
  const errorRef = useRef<Error>();
  const tokenAccess: CheckoutAuthCertificateLens | undefined = useMemo(() => {
    try {
      return CheckoutAuthCertificate(token);
    } catch (e) {
      errorRef.current = e;
      return undefined;
    }
  }, [token]);

  if (errorRef.current) {
    return <GenericErrorPage heading="An error has occurred" body="No available products for merchant" />;
  }

  const { availableProductNames = [], ineligibleProducts = [] } = tokenAccess || {};
  const [firstError] = error;

  if (ineligibleProducts.length === 0) {
    return availableProductNames[0] === ProductNames.REVOLVING_CREDIT ? (
      <NewpayErrorPage onClose={onClose} />
    ) : (
      <ZipErrorPage onClose={onClose} />
    );
  }

  const [firstIneligbleProduct] = ineligibleProducts;
  const [firstProductError] = firstIneligbleProduct.errors;

  if (firstIneligbleProduct.product === ProductNames.REVOLVING_CREDIT) {
    return (
      <>
        {firstProductError.error === CACError.amountTooHigh && (
          <NewpayBasketErrorPage
            error={BasketErrors.valueExceedsCreditLimit}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
            creditLimit={Number(firstProductError.info.maxAmount)}
          />
        )}

        {firstProductError.error === CACError.amountTooLow && (
          <NewpayBasketErrorPage
            error={BasketErrors.valueTooLow}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
            creditMinimumValue={Number(firstProductError.info.minAmount)}
          />
        )}

        {firstProductError.error === CACError.basketItemNotSupported && <NewpayErrorPage onClose={onClose} />}

        {[CACError.amountTooHigh, CACError.amountTooLow, CACError.basketItemNotSupported].indexOf(
          firstProductError.error
        ) === -1 && <NewpayErrorPage onClose={onClose} />}
      </>
    );
  }

  if (firstIneligbleProduct.product === ProductNames.SPLIT) {
    return (
      <>
        {CACError.amountTooLow === firstProductError.error && (
          <ZipBasketErrorPage
            minimumBasket={firstProductError.info.minAmount}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
          />
        )}
        {CACError.amountTooHigh === firstProductError.error && (
          <ZipBasketErrorPage
            maximumBasket={firstProductError.info.maxAmount}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
          />
        )}
        {[CACError.amountTooLow, CACError.amountTooHigh].indexOf(firstProductError.error) === -1 && (
          <ZipErrorPage onClose={onClose} />
        )}
      </>
    );
  }

  if (availableProductNames.length > 0) {
    if (availableProductNames[0] === ProductNames.SPLIT) {
      return <ZipErrorPage onClose={onClose} />;
    }

    return <NewpayErrorPage onClose={onClose} />;
  }

  if (firstError && firstError.product === ProductNames.GENERIC) {
    if (firstError.errors[0].error === CACError.generic) {
      return <GenericErrorPage heading="An error has occurred" body={firstError.errors[0].info.message} />;
    }
  }

  return <GenericErrorPage heading="An error has occurred" body="No available products for merchant" />;
};

export default ErrorPage;
