export enum UnhandledErrors {
  NO_CAC = 'No CAC provided',
  UNKNOWN_ERROR = 'Unknown Error',
  INIT_TOKEN_LOADER = 'Something went wrong: Init Token failed',
}

export enum HandledErrors {
  CAC_LOADER = 'Something went wrong: Checkout token failed',
  PRODUCT = 'Product Error',
  EXPIRED = 'CAC Expired',
  CHECKOUT_FAILED = 'Checkout failed',
}
