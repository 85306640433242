import { ApplicationEventHandler, LenderName, ProductNames, SchemaObject } from '@app-types';
import { CheckoutAuthCertificateLens } from '@utils/CheckoutAuthCertificate/types';
import { DekoWalletCheckoutError } from '@utils/Errors/types';
import { InitCertificateLens } from '@utils/InitCertificate/types';

export enum Pages {
  loading = 'loading',
  products = 'products',
  productDetails = 'productDetails',
  workflow = 'workflow',
  success = 'success',
  fail = 'fail',
  error = 'error',
  zipPreCheckout = 'zipPreCheckout',
  preCheckout = 'preCheckout',
}

export interface WorkflowStepProps {
  authToken?: string;
  readonly authCertificate?: CheckoutAuthCertificateLens;
  initToken: string;
  readonly initCertificate: InitCertificateLens;
  promotional?: number;
  error?: DekoWalletCheckoutError;
  onApplicationEvent: ApplicationEventHandler;
  onClose: () => void;
  onChangeActiveLender: (e: LenderName) => void;
  clearPromotional: () => void;
  disableLoadingScreen?: boolean;
  firstAvailableProduct?: ProductNames;
}

export enum WorkflowType {
  WORKFLOW = 'HOSTED_ASSET',
  REDIRECT = 'FULLPAGE_REDIRECT',
  IMMEDIATE_REDIRECT = 'IMMEDIATE_FULLPAGE_REDIRECT',
  SWITCH = 'APPLICATION_SWITCH',
  SPLASH_ASSET = 'SPLASH_ASSET',
}

export interface StartPreCheckoutWorkflow {
  url: string;
  originUrl: string;
  type?: WorkflowType;
  schema?: SchemaObject;
}
