import { jwtDecode } from 'jwt-decode';

import { CAC } from '@app-types';

// const decode = <T>(cac: CAC): T => JSON.parse(atob(cac)) as T;
const decode = <T>(cac: CAC): T & { claimKey: string } => {
  const decodedToken = jwtDecode<T & { claimKey: string }>(cac);
  return {
    ...decodedToken,
    claimKey: decodedToken?.claimKey || 'https://api.dekopay.com',
  };
};

export default decode;
