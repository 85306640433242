import styled from 'styled-components';

import { FadeyDiv } from '@components/WorkflowStep/styles';

const SplashIFrame = styled(FadeyDiv).attrs((attrs) => ({ isActive: true, ...attrs }))`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
`;
export default SplashIFrame;
