import Cookies from 'js-cookie';

import { LenderName, ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';

export default function getAvailableProduct(availableProductNames: ProductNames[]): ProductNames {
  if (Cookies.get(CookieType.EligibilityCheckLender) === LenderName.NEWPAY) {
    return ProductNames.NEWPAY;
  }
  if (availableProductNames.includes(ProductNames.BLACKHORSE_FLEXPAY)) {
    return ProductNames.BLACKHORSE_FLEXPAY;
  }
  return availableProductNames[0];
}
