import { Molecules, Spinner, ZipFailPage } from 'components-typescript-react';
import React from 'react';

import { ProductNames } from '@app-types';
import AppPropManager from '@utils/AppPropManager';

interface FailPageProps {
  selectedProduct: ProductNames;
  onClose: () => void | Promise<void>;
}

const FailPage: React.FC<FailPageProps> = ({ onClose, selectedProduct }) => {
  switch (selectedProduct) {
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
    case ProductNames.NEWPAY:
    case ProductNames.NEWPAY_STANDALONE:
      return <Molecules.Loader lender={AppPropManager.activeLender} />;
    case ProductNames.SPLIT:
      return <ZipFailPage onClose={onClose} />;
    case ProductNames.INSTALMENT:
    case ProductNames.MULTI_LENDER:
    case ProductNames.DEKO_MONTHLY:
      return <Spinner />;
    case ProductNames.REVOLVING_CREDIT:
      return AppPropManager.isProductVersionV2 ? (
        <Molecules.Loader lender={AppPropManager.activeLender} />
      ) : (
        <Spinner />
      );
    default:
      return null;
  }
};

export default FailPage;
