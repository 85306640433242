export class SessionExpirationAgent {
  private static timer: NodeJS.Timeout | null = null;

  private static _hasExpired = false;

  public static get hasExpired(): boolean {
    return this._hasExpired;
  }

  static startSessionTimer(expirationTime: Date, callback: () => void) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this._hasExpired = true;
      callback();
    }, expirationTime.getTime() - Date.now());
  }
}
