import { WorkflowType } from './types';

export function workflowStringToType(workflowString: string): WorkflowType | undefined {
  try {
    const index = Object.values(WorkflowType).indexOf(workflowString as unknown as WorkflowType);
    if (index === -1) {
      console.error(`Cannot convert workflow string to type ${workflowString}`);
      return undefined;
    }
    return workflowString as unknown as WorkflowType;
  } catch {
    console.error(`Cannot convert workflow string to type ${workflowString}`);
    return undefined;
  }
}
