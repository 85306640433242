import { Instalment } from 'financeInfo/types';

import { FinanceCalculationService } from 'components-typescript-react';

import { ProductNames } from '@app-types';
import { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

const dekoMonthlyFinanceInfo = (offers: Offers[], basketAmount: number) => {
  const instalments = offers
    .filter((offer) => offer.lenderOfferFields)
    .map((offer): Instalment => {
      const calculation = FinanceCalculationService.calculate({
        lender: 'DEKO_MONTHLY',
        apr: offer.apr,
        term: offer.term,
        amountTotalPounds: basketAmount,
        lenderCustomFields: offer.lenderOfferFields,
      });
      return {
        monthlyInstalment: calculation.monthlyPayment,
        total: calculation.totalPayable,
        apr: offer.apr,
        restrictions: offer.restrictions ?? [],
      };
    });

  const validInstalments = getValidOffers(instalments, basketAmount);
  const interestFreeInstalments = validInstalments.filter((offer) => offer.apr === 0);
  const tagFormat = (a: number) => a / 100;
  const { productLogo } = LogosMap[ProductNames.DEKO_MONTHLY];

  return productFinanceInfo(validInstalments, interestFreeInstalments, basketAmount, productLogo, tagFormat);
};

export default dekoMonthlyFinanceInfo;
