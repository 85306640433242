import { useContext } from 'react';

import { ApiClientContext } from './instances';
import { ApiClientContextInterface } from './types';

export { default as ApiClientProvider } from './ApiClientProvider';

export { AdapterVersion } from './types';

export const useApiClient = () => useContext<ApiClientContextInterface>(ApiClientContext);

export default ApiClientContext;
