export type Status = 'accepted' | 'declined' | 'counteroffer';

export type CookieOptions = {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
};

export enum CookieType {
  EligibilityCheckStatus = 'EligibilityCheckStatus',
  EligibilityCheckLimit = 'EligibilityCheckLimit',
  EligibilityCheckLender = 'EligibilityCheckLender',
  EligibilityApplication = 'EligibilityApplication',
  BlackHorseCookieConsent = 'BlackHorseCookieConsent',
  FinanceFirstEnabled = 'FinanceFirstEnabled',
}

export enum LocalStorageType {
  CookieStatus = 'CookieStatus',
  CurrentProductName = 'CurrentProductName',
}
