import styled from 'styled-components';

import { ToggleIconWrapperProps } from './types';

export const MockHeaderPickerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  max-width: 300px;
  overflow: hidden;
  display: flex;
`;

export const ToggleIconWrapper = styled.div<ToggleIconWrapperProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  :hover {
    opacity: 1;
  }
`;
