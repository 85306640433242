import Cookies from 'js-cookie';

import { ApplicationEventType, LenderName, SchemaObject } from '@app-types';
import AppPropManager from '@utils/AppPropManager';
import MessageCommands from '@utils/MessageListener/types';
import sendMessage from '@utils/sendMessage';

import { CookieOptions, CookieType, Status } from './types';

const expires = new Date(new Date().getTime() + 4 * 60 * 60 * 1000); // Expiration time is set to 4 hours
const setOrRemoveCookie = (condition: boolean, cookieName: string, value?: any, cookieOptions?: CookieOptions) => {
  if (condition) {
    Cookies.set(cookieName, value, cookieOptions);
  } else {
    Cookies.remove(cookieName);
  }
};

const updateCookieValues = (lender: LenderName, status: Status, limit?: number, appId?: string) => {
  const isCounterOffer = status === 'counteroffer';
  const isDeclined = status === 'declined';
  let isActive: boolean | undefined;
  const cookieOptions = { expires };

  if (status === 'accepted') isActive = true;
  if (isDeclined) isActive = false;
  if (isCounterOffer) isActive = undefined;

  setOrRemoveCookie(!isCounterOffer, CookieType.EligibilityCheckStatus, isActive, cookieOptions);
  setOrRemoveCookie(!isCounterOffer && !isDeclined, CookieType.EligibilityCheckLimit, limit, cookieOptions);
  setOrRemoveCookie(!isDeclined, CookieType.EligibilityCheckLender, lender, cookieOptions);
  if (status === 'accepted') {
    setOrRemoveCookie(true, CookieType.EligibilityApplication, appId, cookieOptions);
  } else {
    setOrRemoveCookie(false, CookieType.EligibilityApplication);
  }
};

const updateBeaconStatus = (
  schema: SchemaObject,
  onApplicationEvent: (eventType: ApplicationEventType) => void,
  appId: string
) => {
  // TODO: UPDATE WHEN BE IS READY
  const page = schema._page;
  const isFlexPaySuccess = page === 'FlexPayEligibilityApproval' || page === 'FlexPaySuccess';
  const isCounterOffer = page === 'NewPayCounterOffer';
  const isNewPaySuccess = page === 'NewPayEligibilityApprovalPage';
  const isDoNotLend = page === 'FlexPayEligibilityDeclined';
  const isRejectedCounterOffer = page === 'NewPayEligibilityDeclined';

  if (isFlexPaySuccess) {
    updateCookieValues(schema.lender, 'accepted', schema.meta.creditInfo.assumedCreditLimit, appId);
    AppPropManager.handleFinanceInfoUpdate();
  }

  if (isCounterOffer) {
    updateCookieValues(schema.lender, 'counteroffer');
    AppPropManager.handlePartnerChange(schema.lender);
  }

  if (isNewPaySuccess) {
    updateCookieValues(schema.lender, 'accepted', schema.meta.creditInfo.assumedCreditLimit, appId);
    onApplicationEvent(ApplicationEventType.PREQUALIFICATION_ACCEPT);
    AppPropManager.handlePartnerChange(schema.lender);
  }

  if (isDoNotLend || isRejectedCounterOffer) {
    updateCookieValues(undefined, 'declined');
    sendMessage({ message: MessageCommands.PREQUALIFICATION_DECLINE });
  }
};

export default updateBeaconStatus;
