import { ProductNames } from '@app-types';
import { AppPropManager } from '@utils';
import Calculator from '@utils/Calculator';
import { Offers } from '@utils/CheckoutAuthCertificate/types';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';
import specificProductFinanceInfo from './specificProductFinanceInfo';

const revolvingCreditFinanceInfo = (offers: Offers[], valuePence: number) => {
  if (AppPropManager.isProductVersionV2) {
    return specificProductFinanceInfo(offers, valuePence, ProductNames.NEWPAY);
  }

  const instalments = offers.map((offer) => Calculator.instalments(valuePence, offer.term, offer.apr));

  const interestFreeInstalments = offers
    .filter((offer) => offer.apr === 0)
    .map((offer) => Calculator.instalments(valuePence, offer.term, offer.apr));

  const tagFormat = (value: number) => value / 100;
  const { productLogo, eligibilityLogo } = LogosMap[ProductNames.REVOLVING_CREDIT];
  return productFinanceInfo(instalments, interestFreeInstalments, valuePence, productLogo, tagFormat, eligibilityLogo);
};

export default revolvingCreditFinanceInfo;
