import { ProductNames } from '@app-types';
import { AppPropManager, InitCertificate } from '@utils';
import formatMoneyInput from '@utils/FormatMoneyInput';

import dekoMonthlyFinanceInfo from './implementations/dekoMonthly';
import monthlyInstalmentFinanceInfo from './implementations/monthlyInstalment';
import multiLenderFinanceInfo from './implementations/multiLender';
import revolvingCreditFinanceInfo from './implementations/revolvingCredit';
import specificProductFinanceInfo from './implementations/specificProductFinanceInfo';
import { FinanceArgs, FinanceInfo } from './types';

function getOffers(initToken: string, product: ProductNames) {
  if (initToken) {
    const cert = InitCertificate(initToken);
    const offers = cert.productOffers(product);
    AppPropManager.offers = offers;
    return offers.filter((offer) => !offer.special);
  }

  if (AppPropManager.offers.length > 0) {
    return AppPropManager.offers;
  }

  return [];
}

const v2: FinanceInfo = (product, { value, initToken }: FinanceArgs) => {
  const valuePence = formatMoneyInput(value);

  if (valuePence <= 0) {
    return { message: 'Please enter a positive value' };
  }

  const offers = getOffers(initToken, product);

  switch (product) {
    case ProductNames.REVOLVING_CREDIT:
      return revolvingCreditFinanceInfo(offers, valuePence);
    case ProductNames.INSTALMENT:
      return monthlyInstalmentFinanceInfo(offers, valuePence);
    case ProductNames.DEKO_MONTHLY:
      return dekoMonthlyFinanceInfo(offers, valuePence);
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.NEWPAY:
      return specificProductFinanceInfo(offers, valuePence, product);
    case ProductNames.MULTI_LENDER:
      return multiLenderFinanceInfo(offers, valuePence);
    case ProductNames.GENERIC:
    default:
      return { message: 'Please choose a valid financial product' };
  }
};

export default v2;
