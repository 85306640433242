import styled from 'styled-components';

export interface IframeProps {
  title: string;
  key: string;
  src: string;
}

export const Iframe = styled.iframe<IframeProps>`
  height: 100vh;
  width: 100%;
  border: none;
  overflow: hidden;
`;

export interface FadeyProps {
  isActive: boolean;
}

export const FadeyDiv = styled.div<FadeyProps>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: all 0.7s ease-out;
`;
