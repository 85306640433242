import { ThemeVariants } from 'components-typescript-react';

import { LenderName } from '@app-types';

export default function themeSwitching(name?: LenderName): ThemeVariants {
  switch (name) {
    case LenderName.BLACKHORSE:
      return ThemeVariants.flexpay;
    case LenderName.NEWPAY:
      return ThemeVariants.newpay;
    default:
      return ThemeVariants.dark;
  }
}
