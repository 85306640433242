import PropTypes from 'prop-types';

import { ProductNames } from '@app-types';

export enum CACError {
  currencyNotSupported = 'currency:notSupported',
  amountTooHigh = 'amount:tooHigh',
  amountTooLow = 'amount:tooLow',
  basketItemNotSupported = 'basketItem:notSupported',
  generic = 'generic',
  expired = 'expired',
}

export enum BasketErrors {
  valueExceedsCreditLimit = 'VALUE_EXCEEDS_CREDIT_LIMIT',
  valueExceedsLendingLimit = 'VALUE_EXCEEDS_LENDING_LIMIT',
  valueTooLow = 'VALUE_TOO_LOW',
}

export interface ErrorInfoType {
  [key: string]: string[] | number | string;
}

export interface ErrorInfo {
  error: CACError;
}

export interface CurrencyNotSupportedError extends ErrorInfo {
  error: CACError.currencyNotSupported;
  info: {
    supportedCurrencies: string[];
  };
}

export interface AmountTooLowError extends ErrorInfo {
  error: CACError.amountTooLow;
  info: {
    minAmount: number;
  };
}

export interface AmountTooHighError extends ErrorInfo {
  error: CACError.amountTooHigh;
  info: {
    maxAmount: number;
  };
}

export interface BasketItemNotSupportedError extends ErrorInfo {
  error: CACError.basketItemNotSupported;
  info: {
    item: string;
  };
}

export interface CACExpiredError extends ErrorInfo {
  error: CACError.expired;
  info: {
    date: Date;
  };
}

export interface GenericError extends ErrorInfo {
  error: CACError.generic;
  info: {
    message: string;
  };
}

export type SplitValidationErrors = CurrencyNotSupportedError | AmountTooHighError | AmountTooLowError;

export type RevolvingCreditValidationErrors =
  | CurrencyNotSupportedError
  | AmountTooHighError
  | BasketItemNotSupportedError;

export type DekoErrorInfoTypes =
  | SplitValidationErrors
  | RevolvingCreditValidationErrors
  | CACExpiredError
  | GenericError;

export interface DekoErrorInfo {
  errors: DekoErrorInfoTypes[];
  product: ProductNames;
}

export type DekoWalletCheckoutError = DekoErrorInfo[] | undefined;

export const genericValidationErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.generic as CACError.generic]).isRequired,
  info: PropTypes.exact({
    message: PropTypes.string.isRequired,
  }),
});

export const expiredErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.expired as CACError.expired]).isRequired,
  info: PropTypes.exact({
    date: PropTypes.instanceOf(Date).isRequired,
  }),
});

export const amountTooLowValidationErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.amountTooLow as CACError.amountTooLow]).isRequired,
  info: PropTypes.exact({
    minAmount: PropTypes.number.isRequired,
  }),
});

export const amountTooHighValidationErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.amountTooHigh as CACError.amountTooHigh]).isRequired,
  info: PropTypes.exact({
    maxAmount: PropTypes.number.isRequired,
  }),
});

export const basketItemNotSupportedValidationErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.basketItemNotSupported as CACError.basketItemNotSupported]).isRequired,
  info: PropTypes.exact({
    item: PropTypes.string.isRequired,
  }),
});

export const currencyNotSupportedValidationErrorPropType = PropTypes.exact({
  error: PropTypes.oneOf([CACError.currencyNotSupported as CACError.currencyNotSupported]).isRequired,
  info: PropTypes.exact({
    supportedCurrencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
});

export const ErrorPropTypes = PropTypes.arrayOf(
  PropTypes.exact({
    errors: PropTypes.arrayOf(
      PropTypes.oneOfType([
        expiredErrorPropType,
        genericValidationErrorPropType,
        amountTooLowValidationErrorPropType,
        amountTooHighValidationErrorPropType,
        currencyNotSupportedValidationErrorPropType,
        basketItemNotSupportedValidationErrorPropType,
      ])
    ),
    product: PropTypes.oneOf(Object.values(ProductNames) as ProductNames[]),
  })
);
