import React from 'react';

interface GenericErrorPageProps {
  heading: string;
  body: string;
}

const GenericErrorPage: React.FC<GenericErrorPageProps> = ({
  heading = 'An error has occurred',
  body = 'Please retry later',
}) => (
  <>
    <strong>{heading}</strong>
    <p>{body}</p>
  </>
);

export default GenericErrorPage;
