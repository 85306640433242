import Cookies from 'js-cookie';

import { TileMessageData } from 'components-typescript-react';

import CookieValues from '@utils/Cookies/types';
import MessageCommands from '@utils/MessageListener/types';

export interface MessagePayload {
  message: MessageCommands;
  tileMsgData: TileMessageData;
}

const messageHandler = (
  evt: MessageEvent<MessagePayload>,
  closePlugin: () => void,
  sendCustomerInfo?: () => void
): boolean => {
  const in4Hours = 1 / 6;
  const in30Min = 1 / 48;
  switch (evt.data?.message) {
    case MessageCommands.CLOSE_PLUGIN:
      closePlugin();
      return true;
    case MessageCommands.REQUEST_FORM_DATA:
      if (sendCustomerInfo) {
        sendCustomerInfo();
      }
      return true;
    case MessageCommands.PREQUALIFICATION_ACCEPT:
      Cookies.set('EligibilityCheckStatus', CookieValues.PREQUALIFICATION_ACCEPT, {
        path: '',
        domain: '',
        SameSite: 'None',
        secure: true,
      });
      if (!evt.data.tileMsgData?.creditLimit) {
        return;
      }
      Cookies.set('EligibilityCheckLimit', evt.data.tileMsgData?.creditLimit, {
        path: '',
        domain: '',
        SameSite: 'None',
        secure: true,
        expires: evt.data.tileMsgData.channel === 'online' ? in4Hours : in30Min,
      });
      return true;
    default:
      break;
  }

  return false;
};

export default messageHandler;
