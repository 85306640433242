import { Button } from 'components-typescript-react';
import React from 'react';

import { ProductNames } from '@app-types';

type ProductsListProps = {
  products: ProductNames[];
  onSetSelectedProduct: (product: ProductNames) => void;
};

const ProductsList: React.FC<ProductsListProps> = ({ products, onSetSelectedProduct }: ProductsListProps) => {
  React.useEffect(() => {
    if (products.length === 1) {
      onSetSelectedProduct(products[0]);
    }
  }, [products]);

  return (
    <ul>
      {products.map((product) => (
        <li key={product}>
          <Button data-testid={`test-product-${product}`} type="button" onClick={() => onSetSelectedProduct(product)}>
            {product}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default ProductsList;
