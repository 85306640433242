import { CAC } from '@app-types';
import getFirstAvailableProduct from '@utils/getAvailableProduct';
import basket from '@utils/Token/basket';
import basketValue from '@utils/Token/basketValue';
import customerInfo from '@utils/Token/customerInfo';
import expiresOn, { isExpired } from '@utils/Token/expiry';
import getProductNames from '@utils/Token/getProductNames';
import hasBasket from '@utils/Token/hasBasket';
import ineligibleProducts from '@utils/Token/ineligibleProducts';

import decode from '../Token/decode';
import getApplyUrlForProduct from '../Token/getApplyUrlForProduct';
import { CACPayload, CheckoutAuthCertificateLens } from './types';

type Decoder = (cac: CAC) => CheckoutAuthCertificateLens;

const CheckoutAuthCertificate: Decoder = (cac) => {
  try {
    const decodedCertificate = decode<CACPayload>(cac);
    const productNames = getProductNames(decodedCertificate);

    // const dc = decode<CACPayload>(cac);
    // const decodedCertificate = { ...dc, exp: Date.now() / 1000 + 60 } as CACPayload;
    // const products = getProductNames(decodedCertificate);

    return {
      get hasAvailableProducts() {
        return productNames.length > 0;
      },
      get availableProductNames() {
        return productNames;
      },
      get availableProductName() {
        return getFirstAvailableProduct(this.availableProductNames);
      },
      get hasBasket() {
        return hasBasket(decodedCertificate);
      },
      get basket() {
        return basket(decodedCertificate);
      },
      get ineligibleProducts() {
        return ineligibleProducts(decodedCertificate);
      },
      get basketValue() {
        return basketValue(decodedCertificate);
      },
      get isExpired() {
        return isExpired(decodedCertificate);
      },
      get expiresOn() {
        return expiresOn(decodedCertificate);
      },
      get customerInfo() {
        return customerInfo(decodedCertificate);
      },
      getApplyUrl: (product) => getApplyUrlForProduct(decodedCertificate, product),
    };
  } catch (e) {
    console.error(e);
    throw new Error('Invalid CAC 😢');
  }
};

export default CheckoutAuthCertificate;
