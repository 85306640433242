import { MultiLender } from 'financeInfo/types';

import { FinanceCalculationService } from 'components-typescript-react';

import { ProductNames } from '@app-types';
import type { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

const calculateFinanceInfo = (offers: Offers[], basketAmount: number, lender: string) =>
  offers
    .filter((offer) => offer.lenderOfferFields)
    .map((offer): MultiLender => {
      const calculation = FinanceCalculationService.calculate({
        lender,
        amountTotalPounds: basketAmount,
        lenderCustomFields: offer.lenderOfferFields,
      });

      return {
        monthlyInstalment: calculation.monthlyPayment,
        total: calculation.totalPayable,
        apr: offer.apr,
        restrictions: offer.restrictions ?? [],
      };
    });

const multiLenderFinanceInfo = (offers: Offers[], basketAmount: number) => {
  const instalments = calculateFinanceInfo(offers, basketAmount, 'NOVUNA');
  const validInstalments = getValidOffers(instalments, basketAmount);
  const interestFreeInstallments = validInstalments.filter((offer) => offer.apr === 0);
  const { productLogo } = LogosMap[ProductNames.MULTI_LENDER];
  const tagFormat = (a) => a / 100;
  return productFinanceInfo<MultiLender>(
    validInstalments,
    interestFreeInstallments,
    basketAmount,
    productLogo,
    tagFormat
  );
};

export default multiLenderFinanceInfo;
