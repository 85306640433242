import type { Instalment } from 'financeInfo/types';
import Cookies from 'js-cookie';

import type { CalculatorResult } from '@utils/Calculator';
import CookieValues from '@utils/Cookies/types';

const sortByMonthlyInstalment = (a: { monthlyInstalment: number }, b: { monthlyInstalment: number }) => {
  if (a.monthlyInstalment < b.monthlyInstalment) {
    return -1;
  }
  if (a.monthlyInstalment > b.monthlyInstalment) {
    return 1;
  }
  return 0;
};

const productFinanceInfo = <T extends Instalment | CalculatorResult>(
  instalments: T[],
  interestFreeInstalments: T[],
  formattedValue: number,
  financeProductLogo: string,
  tagFormat: (value: number) => number,
  financeEligibilityLogo = 'https://assets.dekopay.com/eligibility-success.png',
  eligibleTagline = "You're Eligible",
  lowestInterestLogic = false
) => {
  let lowestInterestFreeOffer: number;
  let lowestInterestBearingOffer: number;

  const customerIsEligible: boolean =
    Cookies.get('EligibilityCheckStatus') === CookieValues.PREQUALIFICATION_ACCEPT ||
    Cookies.get('EligibilityCheckStatus') === 'true';

  if (instalments.length !== 0) {
    if (lowestInterestLogic) {
      const allInstalments = [...instalments, ...interestFreeInstalments];
      allInstalments.sort(sortByMonthlyInstalment);
      const lowestInterestOffer = allInstalments[0] as Instalment;

      lowestInterestFreeOffer = lowestInterestOffer.apr === 0 ? lowestInterestOffer.monthlyInstalment : undefined;
      lowestInterestBearingOffer = lowestInterestOffer.apr !== 0 ? lowestInterestOffer.monthlyInstalment : undefined;
    } else {
      if (interestFreeInstalments.length !== 0) {
        interestFreeInstalments.sort(sortByMonthlyInstalment);
        lowestInterestFreeOffer = interestFreeInstalments[0].monthlyInstalment;
      }
      instalments.sort(sortByMonthlyInstalment);
      const lowestInterestBearingOfferCalculation = instalments[0];

      lowestInterestBearingOffer = lowestInterestBearingOfferCalculation.monthlyInstalment;
    }
  }

  const monthlyInstalment = () => {
    if (lowestInterestFreeOffer) {
      return lowestInterestFreeOffer;
    }

    if (lowestInterestBearingOffer) {
      return lowestInterestBearingOffer;
    }

    return undefined;
  };

  const total = () => formattedValue;

  const tagline = (): string => {
    if (lowestInterestFreeOffer) {
      return `From £${tagFormat(lowestInterestFreeOffer).toFixed(2)} a month with 0% interest.${
        !customerIsEligible ? ' Credit subject to status.' : ''
      }`;
    }
    if (lowestInterestBearingOffer) {
      return `Instalment plans ${
        !customerIsEligible ? 'available.' : `from £${tagFormat(lowestInterestBearingOffer).toFixed(2)}`
      }${!customerIsEligible ? ' Credit subject to status' : ' per month'}.`;
    }
    return `Finance available.${!customerIsEligible ? ' Credit subject to status.' : ''}`;
  };

  const financeEligibleLogo = financeEligibilityLogo;

  const baseFinanceInfo = {
    total: Math.floor(total()),
    monthlyInstalment: monthlyInstalment() ? Math.floor(monthlyInstalment()) : undefined,
    financeProductLogo,
    tagline: tagline(),
    cta: 'Learn more',
  };

  return {
    ...baseFinanceInfo,
    ...(customerIsEligible && {
      eligibleTagline,
      financeEligibleLogo,
    }),
  };
};

export default productFinanceInfo;
