import {
  ApplicationActionPayload,
  ApplicationDeniedPayload,
  ApplicationFailurePayload,
  ApplicationReferredPayload,
} from '@app-types';

export const parseFinalWorkflowStatus = (status: ApplicationReferredPayload | ApplicationFailurePayload) => ({
  // url: 'http://localhost:3000/', // change this if you want to test locally
  url: status.url,
  schema: status._links?.workflow?.jsonSchema,
  type: status._links?.workflow?.type,
});

export const parseActionWorkflowStatus = (status: ApplicationActionPayload | ApplicationDeniedPayload) => ({
  // url: 'http://localhost:3000/', // change this if you want to test locally
  url: status.url,
  submit: status._links?.workflow?.submit?.uri,
  schema: status._links?.workflow?.jsonSchema,
  type: status._links?.workflow?.type,
  pollInterval: status.pollInterval,
});
