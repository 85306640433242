import financeInfo from './financeInfo/financeInfo';
import init from './init';

export const getVersion = (): string => {
  return process.env.DEKO_WALLET_VERSION || 'unknown';
};

window.dekoWallet = { init, financeInfo, getVersion };
window.flexpayWidget = { init, financeInfo, getVersion };

export const { dekoWallet, flexpayWidget } = window;
export default { dekoWallet: window.dekoWallet, flexpayWidget: window.flexpayWidget };
