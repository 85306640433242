import { Instalment } from 'financeInfo/types';

import { FinanceCalculationService } from 'components-typescript-react';

import { ProductNames } from '@app-types';
import type { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

const monthlyInstalmentFinanceInfo = (offers: Offers[], basketAmount: number) => {
  const instalments = offers
    .filter((offer) => offer.lenderOfferFields)
    .map((offer): Instalment => {
      const calculation = FinanceCalculationService.calculate({
        lender: 'BNPP',
        amountTotalPounds: basketAmount, // totalAmountPounds can actually be pence or pounds, as long as the result is treated the same
        lenderCustomFields: offer.lenderOfferFields,
      });

      return {
        monthlyInstalment: calculation.monthlyPayment,
        total: calculation.totalPayable,
        apr: offer.apr,
        restrictions: offer.restrictions ?? [],
      };
    });

  const validInsalments = getValidOffers(instalments, basketAmount);

  const interestFreeInstallments = validInsalments.filter((offer) => offer.apr === 0);
  const { productLogo } = LogosMap[ProductNames.INSTALMENT];

  const tagFormat = (a) => a / 100;

  return productFinanceInfo<Instalment>(
    validInsalments,
    interestFreeInstallments,
    basketAmount,
    productLogo,
    tagFormat
  );
};

export default monthlyInstalmentFinanceInfo;
