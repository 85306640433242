import equal from 'fast-deep-equal';

import { SchemaObject } from '@app-types';

function getObjectWithoutSelectedFields(source: SchemaObject | undefined): SchemaObject | undefined {
  if (!source) {
    return undefined;
  }

  const obj: SchemaObject = {
    ...source,
    timestamp: '',
    meta: {
      ...source?.meta,
      iframeUri: '',
      document: {
        ...source?.meta?.document,
        html: null,
        pdf: null,
      },
    },
  };

  return obj;
}

export default function isNewSchema(
  schema: SchemaObject | undefined,
  previousSchema: SchemaObject | undefined
): boolean {
  if (previousSchema?.timestamp !== undefined && schema?.timestamp !== undefined) {
    // console.log(`Testing Schema TimeStamp ${schema.timestamp}`);

    const prevTs = new Date(previousSchema.timestamp);
    const newTs = new Date(schema.timestamp);
    if (prevTs > newTs) {
      // console.log(`Wrong Sequence - Drop frame: ${schema?.timestamp} ${previousSchema.timestamp} ${schema.timestamp}`);
      return false;
    }
  }

  if (schema?._id === undefined) {
    const oldSchema = getObjectWithoutSelectedFields(previousSchema);
    const newSchema = getObjectWithoutSelectedFields(schema);
    if (!equal(oldSchema, newSchema)) {
      // console.log(`change from ${JSON.stringify(previousSchema)} to ${JSON.stringify(schema)}`);
      return true;
    }

    return false;
  }

  return schema?._id !== previousSchema?._id;
}
