import { Molecules, Spinner } from 'components-typescript-react';
import React from 'react';

import { ProductNames } from '@app-types';
import { FadeyDiv } from '@components/WorkflowStep/styles';
import AppPropManager from '@utils/AppPropManager';

import { LoaderContainer } from './styles';

export interface LoaderProps {
  isActive: boolean;
  selectedProduct: ProductNames;
}

const Loader: React.FC<LoaderProps> = ({ isActive, selectedProduct }) => {
  switch (selectedProduct) {
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
    case ProductNames.NEWPAY:
    case ProductNames.NEWPAY_STANDALONE:
      return isActive ? (
        <LoaderContainer>
          <Molecules.Loader lender={AppPropManager.activeLender} />
        </LoaderContainer>
      ) : null;
    case ProductNames.SPLIT:
    case ProductNames.INSTALMENT:
    case ProductNames.DEKO_MONTHLY:
    case ProductNames.MULTI_LENDER:
      return (
        <FadeyDiv isActive={isActive}>
          <Spinner text="" />
        </FadeyDiv>
      );
    case ProductNames.REVOLVING_CREDIT:
      return AppPropManager.isProductVersionV2 && isActive ? (
        <LoaderContainer>
          <Molecules.Loader lender={AppPropManager.activeLender} />
        </LoaderContainer>
      ) : (
        <FadeyDiv isActive={isActive}>
          <Spinner text="" />
        </FadeyDiv>
      );
    default:
      return null;
  }
};

export default Loader;
