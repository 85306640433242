import { ProductNames } from '@app-types';
import { InitPayloadSplash, Offers } from '@utils/CheckoutAuthCertificate/types';

export interface ProductPayload {
  claimKey: string;
  [key: `${string}/product`]: {
    [ProductNames.REVOLVING_CREDIT]?: {
      offers: Offers[];
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
    [ProductNames.INSTALMENT]?: {
      offers: Offers[];
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
    [ProductNames.DEKO_MONTHLY]?: {
      offers: Offers[];
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
    [ProductNames.NEWPAY]?: {
      offers: Offers[];
      splash?: InitPayloadSplash;
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
    [ProductNames.SPLIT]?: object;
    [ProductNames.BLACKHORSE_FLEXPAY]?: {
      offers: Offers[];
      splash?: InitPayloadSplash;
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
  };
}

const getProductNames = (payload: ProductPayload): ProductNames[] => {
  if (!payload[`${payload.claimKey}/product`]) return []; // ClaimKey may be provided?
  return Object.keys(payload[`${payload.claimKey}/product`]) as ProductNames[];
};

export default getProductNames;
