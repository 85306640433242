import { ProductNames } from '@app-types';
import calculateInstalment from '@utils/calculateInstalment';
import type { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

const specificProductFinanceInfo = (
  offers: Offers[],
  amount: number,
  productName: ProductNames = ProductNames.BLACKHORSE_FLEXPAY
) => {
  const basketAmount = amount / 100;
  const validOffers = getValidOffers(offers, amount);

  const instalments = validOffers.map((offer) => calculateInstalment(basketAmount, offer));
  const interestFreeInstalments = validOffers
    .filter((offer) => offer.apr === 0)
    .map((offer) => calculateInstalment(basketAmount, offer));
  const { productLogo, eligibilityLogo } = LogosMap[productName];
  const tagFormat = (value: number) => value;

  return productFinanceInfo(
    instalments,
    interestFreeInstalments,
    amount,
    productLogo,
    tagFormat,
    eligibilityLogo,
    "You're Eligible",
    true
  );
};

export default specificProductFinanceInfo;
