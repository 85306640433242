import Cookies from 'js-cookie';

import { ProductNames } from '@app-types';
import AppPropManager from '@utils/AppPropManager';
import { CACPayload } from '@utils/CheckoutAuthCertificate/types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';
import { InitPayload } from '@utils/InitCertificate/types';

const getApplyUrlForProduct = (payload: CACPayload | InitPayload, product: ProductNames, cookies = Cookies): string => {
  let financeFirstEnabled: boolean = false;
  if (product === ProductNames.REVOLVING_CREDIT && !AppPropManager.isProductVersionV2) {
    if (`${payload.claimKey}/product` in payload && 'env' in payload && payload.env != null) {
      financeFirstEnabled = payload.env?.featureFlags?.['seas-516-finance-first-enabled'];
      if (financeFirstEnabled != null) {
        cookies.set(CookieType.FinanceFirstEnabled, financeFirstEnabled);
      }
    }
  }

  return payload[`${payload.claimKey}/product`][product]._links['product:apply'].href;
};

export default getApplyUrlForProduct;
