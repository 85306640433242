import Cookie from 'js-cookie';
import type { EnvironmentVariableContextInterface } from 'components-typescript-react/dist/components/Workflow/atoms/ENVCONTEXT/ENVCONTEXT';
import React from 'react';

import { LenderName, ProductNames } from '@app-types';
import { Iframe } from '@components/WorkflowStep/styles';
import { AppPropManager } from '@utils';
import createIFrameData, { IFrameData } from '@utils/createIFrameData';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';
import { InitCertificateLens } from '@utils/InitCertificate/types';

import SplashIFrame from './styles';
import { IFrameRemoveEventListener, onLoadIFrame, onMessageIFrame } from './utils';
import ZipSplashWithProduct from './ZipSplashWithProduct';

export interface SplashWithProductProps {
  initCertificate: InitCertificateLens;
  productAmount: number;
  environmentVariables: EnvironmentVariableContextInterface;
  selectedProduct: ProductNames;
}
const SplashWithProduct: React.FC<SplashWithProductProps> = ({
  initCertificate,
  productAmount,
  environmentVariables,
  selectedProduct,
}) => {
  const frameRef = React.useRef<HTMLIFrameElement>(null);
  const product = React.useMemo<ProductNames>(() => {
    const isNewPayLender = Cookie.get(CookieType.EligibilityCheckLender) === LenderName.NEWPAY;
    return isNewPayLender && !AppPropManager.isProductVersionV2 ? ProductNames.NEWPAY : selectedProduct;
  }, [selectedProduct]);
  const iFrameData = React.useMemo<IFrameData>(
    () =>
      product !== ProductNames.SPLIT && createIFrameData({ initCertificate, selectedProduct: product, productAmount }),
    [product]
  );

  React.useEffect(() => {
    let cleanup: IFrameRemoveEventListener;
    if (frameRef.current) {
      if (
        product === ProductNames.NEWPAY ||
        product === ProductNames.BLACKHORSE_FLEXPAY ||
        (product === ProductNames.REVOLVING_CREDIT && AppPropManager.isProductVersionV2)
      ) {
        cleanup = onLoadIFrame({
          iFrame: frameRef.current,
          schema: initCertificate.getSplash(product).schema,
          environmentVariables,
          originUrl: iFrameData.originUrl,
          productAmount,
        });
      } else {
        cleanup = onMessageIFrame({ iFrame: frameRef.current, originUrl: iFrameData.originUrl });
      }
    }
    if (cleanup) return cleanup;
  }, [initCertificate, iFrameData, environmentVariables, product, productAmount]);
  if (selectedProduct === ProductNames.SPLIT) {
    return <ZipSplashWithProduct productAmount={productAmount} />;
  }

  return (
    <SplashIFrame
      as={Iframe}
      data-testid="splash-with-product-iframe"
      title={iFrameData.schema?.title}
      key={iFrameData.url}
      src={iFrameData.url}
      ref={frameRef}
    />
  );
};
export default SplashWithProduct;
