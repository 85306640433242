import { LenderName, ProductNames } from '@app-types';
import AppPropManager from '@utils/AppPropManager';

const getLenderFromProduct = (product: ProductNames | LenderName): LenderName | undefined => {
  switch (product) {
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
      return LenderName.BLACKHORSE;
    case ProductNames.NEWPAY:
    case ProductNames.NEWPAY_STANDALONE:
      return LenderName.NEWPAY;
    case ProductNames.REVOLVING_CREDIT:
      return AppPropManager.isProductVersionV2 ? LenderName.NEWPAY : undefined;
    default:
      return undefined;
  }
};

export default getLenderFromProduct;
