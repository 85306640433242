import { Organisms, Templates } from 'components-typescript-react';
import { FormBuilderProps } from 'components-typescript-react/dist/components/Workflow/FormBuilder/types';
import React from 'react';

import NewpayErrorSchema from '../../schemas/newpay-error-page.json';
import NewPaySessionExpiredSchema from '../../schemas/newpay-session-expired-page.json';
import NewPaySessionExpiredStandAloneSchema from '../../schemas/newpay-session-expired-standalone-page.json';

const NewpayErrorPage = Organisms.NewPayGenericError as React.FC<FormBuilderProps>;
const NewPaySessionExpired = Templates.NewPaySessionExpired as React.FC<FormBuilderProps>;

interface NewpayErrorScreenProps {
  isSessionExpired: boolean;
  isStandAlone: boolean;
  onClose: () => void;
}

const NewpayErrorScreen: React.FC<NewpayErrorScreenProps> = ({ isSessionExpired, isStandAlone, onClose }) => {
  const schema = !isStandAlone ? NewPaySessionExpiredSchema : NewPaySessionExpiredStandAloneSchema;
  let error = <NewpayErrorPage schema={NewpayErrorSchema as any} onClose={onClose} />;

  const handleClose = () => {
    onClose();
    if (isSessionExpired) {
      window.location.reload();
    }
  };

  if (isSessionExpired) {
    error = <NewPaySessionExpired schema={schema as any} onClose={handleClose} />;
  }
  return error;
};

export default NewpayErrorScreen;
