import { useRef } from 'react';

import { SchemaObject } from '@app-types';

export interface InitialWorkflowState {
  url: string;
  originUrl: string;
  type: string;
  schema: SchemaObject;
  submit?: string;
  pollInterval?: number;
}

const useWorkflowState = () => {
  const submitAction = useRef<string>(null);
  const workflowUrl = useRef<string>(null);
  const workflowSchema = useRef<SchemaObject>(null);
  const workflowOrigin = useRef<string>(null);
  const workflowType = useRef<string>(null);
  const workflowPollInterval = useRef<number>(null);

  const initialWorkflowState = useRef<InitialWorkflowState>({
    url: null,
    originUrl: null,
    type: null,
    schema: null,
    submit: null,
    pollInterval: null,
  });

  return {
    submitAction,
    workflowUrl,
    workflowSchema,
    workflowOrigin,
    workflowType,
    workflowPollInterval,
    initialWorkflowState,
  };
};

export default useWorkflowState;
