import { CACPayload } from '@utils/CheckoutAuthCertificate/types';
import { InitPayload } from '@utils/InitCertificate/types';

const MS: number = 1000;

const expiresOn = (payload: CACPayload | InitPayload): Date | undefined => {
  const expiry: Date = new Date(payload.exp * MS);
  return Number.isNaN(expiry.getTime()) ? undefined : expiry;
};

export const isExpired = (payload: CACPayload | InitPayload): boolean => {
  const expiry: Date | undefined = expiresOn(payload);

  if (!expiry) {
    return false;
  }

  return new Date().getTime() > expiry.getTime();
};

export default expiresOn;
