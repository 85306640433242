import { Buffer } from 'buffer';
import Cookies from 'js-cookie';

import { Atoms } from 'components-typescript-react';
import React, { useCallback } from 'react';

import {
  Application,
  ApplicationEventType,
  ApplicationFormattedMessages,
  ApplicationReasons,
  ApplicationStatus,
  type ApplicationStatusPayload,
  CreateApplicationStatus,
  type CreateApplicationSuccess,
  ProductNames,
} from '@app-types';
import { useApiClient } from '@components/ApiClient';
import type { Listener } from '@components/ApiClient/types';
import FailPage from '@components/FailPage';
import MockHeaderPicker from '@components/MockHeaderPicker/MockHeaderPicker';
import SplashPreCheckout from '@components/SplashPreCheckout';
import SplashWithProduct from '@components/SplashWithProduct';
import SuccessPage from '@components/SuccessPage';
import { AppPropManager, PublicApiAdapter } from '@utils';
import handleCookieUIs from '@utils/Cookies';
import CookieValues from '@utils/Cookies/types';
import updateBeaconStatus from '@utils/eligibilityBeaconPersistence';
import { CookieType, LocalStorageType } from '@utils/eligibilityBeaconPersistence/types';
import { trackComponentRenderEvent, trackInteractionEvent, trackPageViewEvent } from '@utils/Event/trackEvent';
import {
  TrackComponentRenderEventArgs,
  TrackComponentRenderEventMessageValues,
  TrackInteractionEventArgs,
  TrackInteractionEventMessageValues,
  TrackPageViewEventArguments,
} from '@utils/Event/types';
import messageHandler from '@utils/MessageListener';
import MessageCommands from '@utils/MessageListener/types';
import { PrecheckoutStateManager } from '@utils/PrecheckoutStateManager';
import { PrecheckoutPersistance } from '@utils/PrecheckoutStateManager/types';
import { SessionExpirationAgent } from '@utils/SessinExpirationAgent';

import ErrorPage from '@components/ErrorPage';
import Loader from '../Loader';
import BlackhorseErrorScreen from './components/BlackhorseErrorScreen';
import NewpayErrorScreen from './components/NewpayErrorScreen';
import { workflowStringToType } from './helper';
import useFrameMessaging, { type FrameSend } from './hooks/useFrameMessaging';
import useWorkflowState, { InitialWorkflowState } from './hooks/useWorkflowState';
import { encodeBase64, INSTALMENT_BASE_URL } from './productInfo';
import { FadeyDiv, Iframe } from './styles';
import { Pages, type StartPreCheckoutWorkflow, type WorkflowStepProps, WorkflowType } from './types';
import { Commands } from './utils/constants';
import getOriginOfUrl from './utils/getOriginOfUrl';
import isNewSchema from './utils/isNewSchema';
import { parseActionWorkflowStatus, parseFinalWorkflowStatus } from './utils/parseWorkflowStatus';

let listener: Listener;

const WorkflowStep: React.FC<WorkflowStepProps> = ({
  authToken,
  authCertificate,
  initToken,
  initCertificate,
  promotional,
  error,
  onApplicationEvent,
  onClose,
  onChangeActiveLender,
  clearPromotional,
  disableLoadingScreen,
}) => {
  const {
    initialWorkflowState,
    workflowUrl,
    workflowOrigin,
    workflowType,
    submitAction,
    workflowPollInterval,
    workflowSchema,
  } = useWorkflowState();
  const {
    availableProductNames = [],
    customerInfo,
    basketValue,
    hasBasket,
  } = React.useMemo(
    () =>
      authCertificate || {
        basketValue: undefined,
        customerInfo: undefined,
        availableProductNames: [],
        hasBasket: false,
      },
    [authCertificate]
  );
  const { isStandAlone } = initCertificate;
  const [selectedProduct, setSelectedProduct] = React.useState<ProductNames>(() => {
    if (authToken) {
      if (availableProductNames.includes(ProductNames.BLACKHORSE_FLEXPAY)) {
        return ProductNames.BLACKHORSE_FLEXPAY;
      }
      return availableProductNames[0];
    }

    return initCertificate.availableProductNames[0];
  });

  const actionPayloadRef = React.useRef<ApplicationStatusPayload>();
  const [page, setPage] = React.useState<Pages>(Pages.loading);
  const [schemaKey, setSchemaKey] = React.useState<number>(null);
  const environmentVariables = React.useContext(Atoms.ENVCONTEXT) || {};
  const apiClient = useApiClient();

  if (promotional) {
    apiClient.unsubscribe(listener);
    PrecheckoutStateManager.clearPrecheckoutStateByKey('application');
    const { url, originUrl, type, schema, submit } = initialWorkflowState.current;

    workflowUrl.current = url;
    workflowOrigin.current = originUrl;
    workflowType.current = type;
    workflowSchema.current = schema;
    submitAction.current = submit;
  }

  const safeSetPage = useCallback(
    (targetPage: Pages) => {
      if (SessionExpirationAgent.hasExpired) {
        setPage(Pages.error);
      } else {
        if (disableLoadingScreen && targetPage === Pages.loading && SessionExpirationAgent.hasExpired) {
          return;
        }
        setPage(targetPage);
      }
    },
    [disableLoadingScreen]
  );

  const isRevolvingCreditProduct = React.useMemo(
    () => selectedProduct === ProductNames.REVOLVING_CREDIT && !AppPropManager.isProductVersionV2,
    [selectedProduct]
  );
  const isLegacyProducts = React.useMemo(
    () => isRevolvingCreditProduct || selectedProduct === ProductNames.SPLIT,
    [isRevolvingCreditProduct, selectedProduct]
  );
  const isPreCheckout = React.useMemo(
    () => (typeof promotional !== 'undefined' && promotional > 0) || !authToken,
    [authToken, promotional]
  );
  const eligibilityCheckStatus = React.useMemo(() => Cookies.get(CookieType.EligibilityCheckStatus), []);
  const financeFirstEnabled = React.useMemo(() => Cookies.get(CookieType.FinanceFirstEnabled) === 'true', []);
  const isBlackHorse = selectedProduct === ProductNames.BLACKHORSE_FLEXPAY || selectedProduct === ProductNames.NEWPAY;
  const precheckoutPersistance: 'enabled' | 'disabled' = !isStandAlone && !hasBasket ? 'enabled' : 'disabled';

  listener = (actionPayload: ApplicationStatusPayload, appId: string): void | Promise<void> => {
    if (
      actionPayload.status === ApplicationStatus.actionRequired ||
      actionPayload.status === ApplicationStatus.denied
    ) {
      const { url, submit, schema, type: workflowString, pollInterval } = parseActionWorkflowStatus(actionPayload);
      const hasChangedUrl = workflowUrl.current !== url;

      if (actionPayload.status === ApplicationStatus.denied) {
        PrecheckoutStateManager.clearPrecheckoutState();
      }

      if (isLegacyProducts) {
        if (hasChangedUrl) {
          if (
            isRevolvingCreditProduct &&
            financeFirstEnabled &&
            eligibilityCheckStatus !== CookieValues.PREQUALIFICATION_ACCEPT
          ) {
            workflowUrl.current = `${url}${financeFirstEnabled ? '&financeFirst=true' : ''}`;
          } else {
            workflowUrl.current = url;
          }
          workflowOrigin.current = getOriginOfUrl(url);
          safeSetPage(Pages.workflow);
        }
        return;
      }

      const hasNewSchema = isNewSchema(schema, workflowSchema.current);

      if (hasChangedUrl || hasNewSchema) {
        workflowUrl.current = url;
        workflowOrigin.current = getOriginOfUrl(url);
        workflowSchema.current = schema;
        submitAction.current = submit;
        workflowPollInterval.current = pollInterval;
        workflowType.current = workflowStringToType(workflowString);

        if (Array.isArray(schema.meta?.offers)) {
          AppPropManager.setOffers(schema.meta.offers);
        }

        AppPropManager.activeLender = schema.lender;
        AppPropManager.isShownWIQButton = [
          'FlexPaySplash',
          'FlexPaySplashNoProduct',
          'FlexPayEligibilitySplashWithProduct',
          'FlexPayWIQ',
          'NewPayCounterOffer',
        ].includes(schema._page);

        onChangeActiveLender(schema.lender);
        safeSetPage(Pages.workflow);
        setSchemaKey(Date.now());
        updateBeaconStatus(schema, onApplicationEvent, appId);
      }
      return;
    }

    if (actionPayload.status === ApplicationStatus.referred) {
      const { schema, type: workflowString, url } = parseFinalWorkflowStatus(actionPayload);
      const hasNewSchema = isNewSchema(schema, workflowSchema.current);

      if (hasNewSchema) {
        workflowUrl.current = url;
        workflowOrigin.current = getOriginOfUrl(url);
        workflowSchema.current = schema;
        workflowType.current = workflowStringToType(workflowString);

        AppPropManager.activeLender = schema.lender;
        onChangeActiveLender(schema.lender);

        safeSetPage(Pages.workflow);
        setSchemaKey(Date.now());
        updateBeaconStatus(schema, onApplicationEvent, appId);
      }
      onApplicationEvent(ApplicationEventType.REFERRED);
      safeSetPage(Pages.workflow);
      return;
    }

    if (actionPayload.status === ApplicationStatus.success) {
      safeSetPage(Pages.success);
      onApplicationEvent(ApplicationEventType.SUCCESS, actionPayload.receipt);

      if (isLegacyProducts || isBlackHorse) apiClient.unsubscribe(listener);
      return;
    }

    if (actionPayload.status === ApplicationStatus.failure) {
      PrecheckoutStateManager.clearPrecheckoutState();
      if (actionPayload.reason === ApplicationReasons.REFERRED) {
        onApplicationEvent(ApplicationEventType.FAIL, ApplicationFormattedMessages.FAILED_REFERRED);
      } else {
        onApplicationEvent(ApplicationEventType.FAIL);
      }

      if (isLegacyProducts) {
        apiClient.unsubscribe(listener);
        if (!isRevolvingCreditProduct) safeSetPage(Pages.fail);
      } else {
        const { schema, type: workflowString, url } = parseFinalWorkflowStatus(actionPayload);
        const hasNewSchema = isNewSchema(schema, workflowSchema.current);
        if (hasNewSchema) {
          workflowUrl.current = url;
          workflowOrigin.current = getOriginOfUrl(url);
          workflowSchema.current = schema;
          workflowType.current = workflowStringToType(workflowString);

          AppPropManager.activeLender = schema.lender;
          onChangeActiveLender(schema.lender);

          safeSetPage(Pages.workflow);
          setSchemaKey(Date.now());
          updateBeaconStatus(schema, onApplicationEvent, appId);
        }
      }
    }

    if (actionPayload.status === ApplicationStatus.error) {
      apiClient.unsubscribe(listener);
      if (!workflowUrl.current) {
        workflowUrl.current = `${INSTALMENT_BASE_URL}index.html`;
      }
      safeSetPage(Pages.error);
    }
  };

  const subscribeAndStartPolling = (application: CreateApplicationSuccess): CreateApplicationSuccess => {
    safeSetPage(Pages.loading);
    apiClient.subscribe(listener);
    apiClient.startPolling(application.pollUrl, application.pollInterval, authToken);
    return application;
  };

  const createApplication = async (withPolling = true): Promise<CreateApplicationSuccess> => {
    try {
      let application: Application;
      const persistedApplication: PrecheckoutPersistance['application'] | null =
        PrecheckoutStateManager.getPrecheckoutStateByKey('application');

      if (persistedApplication == null || precheckoutPersistance === 'disabled') {
        application = await apiClient.createApplication(selectedProduct, authToken);
      } else {
        application = persistedApplication;
      }

      if (application.status === CreateApplicationStatus.success) {
        if (precheckoutPersistance === 'enabled') {
          PrecheckoutStateManager.setPrecheckoutStateByKey('application', application);
        }
        return withPolling ? subscribeAndStartPolling(application) : application;
      }

      throw new Error(`Failed to create application: ${application.status}`);
    } catch (e) {
      console.error(e);
      safeSetPage(Pages.error);
    }
  };

  const onFrameReady = (send: (payload: any) => void) => {
    if (!isPreCheckout) {
      const message = {
        jsonSchema: workflowSchema.current,
        env: environmentVariables,
        cookieStatus: localStorage.getItem(LocalStorageType.CookieStatus),
        origin: window.location.origin,
        isStandalone: isStandAlone,
        ...handleCookieUIs(
          localStorage.getItem(LocalStorageType.CurrentProductName) as ProductNames,
          workflowSchema.current?.lender
        ),
      };

      send({
        type: Commands.ALL,
        data: Buffer.from(JSON.stringify(message)).toString('base64'),
      });
    }
    const trackEventProps: TrackPageViewEventArguments = {
      pageId: workflowSchema.current?._pageId,
      isStandalone: isStandAlone,
      apiClient: { captureEvent: apiClient.captureEvent, appId: apiClient.appId },
      url: initCertificate.eventApiUrl,
      token: isPreCheckout ? initToken : authToken,
      isInsideCheckout: AppPropManager.isCheckoutToken,
    };
    trackPageViewEvent(trackEventProps);
  };

  const submitSplashAssetPayload = async (values: any): Promise<void> => {
    safeSetPage(Pages.loading);
    const { pollUrl, pollInterval } = await createApplication(false);
    const adapter = new PublicApiAdapter();
    const actionPayload = await adapter.getStatus(pollUrl, authToken);

    if (actionPayload.status === ApplicationStatus.actionRequired) {
      const { submit } = parseActionWorkflowStatus(actionPayload);

      await adapter.sendPayload(values, submit, authToken);

      apiClient.subscribe(listener);
      apiClient.startPolling(pollUrl, pollInterval, authToken);
    }
  };

  const onFrameMessage = React.useCallback(
    (m: MessageEvent<any>, send: FrameSend) => {
      if (isPreCheckout) {
        messageHandler(m, onClose, () => {
          console.log('precheckout frame messsage', m);
        });
      }

      if (isLegacyProducts) {
        if (
          m.data?.message === 'LOWEST_MERCHANT_OFFER' ||
          m.data?.message === 'LOWEST_ELIGIBLE_OFFER' ||
          m.origin !== workflowOrigin.current
        ) {
          return;
        }
        messageHandler(m, onClose, () => send({ message: 'SEND_FORM_DATA', formData: customerInfo }));
      } else {
        const { message, submitValues, eventValues } = m.data;
        switch (message) {
          case 'SEND_SUBMIT_DATA': {
            const values = JSON.parse(Buffer.from(submitValues, 'base64').toString());

            if (
              workflowType.current &&
              submitAction.current &&
              workflowType.current === WorkflowType.REDIRECT.valueOf()
            ) {
              onApplicationEvent(ApplicationEventType.REDIRECT);
              window.location.href = submitAction.current;
              return;
            }

            if (
              workflowType.current === WorkflowType.WORKFLOW &&
              AppPropManager.isShownWIQButton &&
              AppPropManager.productAmount
            ) {
              clearPromotional();
              submitSplashAssetPayload(values);
            }

            if (workflowType.current === WorkflowType.SPLASH_ASSET) {
              clearPromotional();
              submitSplashAssetPayload(values);
              return;
            }

            if (submitAction.current && apiClient) {
              try {
                apiClient.sendPayloadPost(values, submitAction.current, authToken);
                safeSetPage(Pages.loading);
              } catch (e) {
                safeSetPage(Pages.error);
              }
            }
            break;
          }
          case 'TRACK_INTERACTION_EVENT': {
            const values: TrackInteractionEventMessageValues = JSON.parse(
              Buffer.from(eventValues, 'base64').toString()
            );

            const payload: TrackInteractionEventArgs = {
              ...values,
              pageId: workflowSchema?.current?._pageId,
              isStandalone: isStandAlone,
              apiClient: { captureEvent: apiClient.captureEvent, appId: apiClient.appId },
              url: initCertificate.eventApiUrl,
              token: isPreCheckout ? initToken : authToken,
              isInsideCheckout: AppPropManager.isCheckoutToken,
            };

            trackInteractionEvent(payload);
            break;
          }
          case 'TRACK_COMPONENT_RENDER_EVENT': {
            const values: TrackComponentRenderEventMessageValues = JSON.parse(
              Buffer.from(eventValues, 'base64').toString()
            );

            const payload: TrackComponentRenderEventArgs = {
              ...values,
              pageId: workflowSchema?.current?._pageId,
              isStandalone: isStandAlone,
              apiClient: { captureEvent: apiClient.captureEvent, appId: apiClient.appId },
              url: initCertificate.eventApiUrl,
              token: isPreCheckout ? initToken : authToken,
              isInsideCheckout: AppPropManager.isCheckoutToken,
            };

            trackComponentRenderEvent(payload);
            break;
          }
          case 'CLOSE_PLUGIN':
            onClose();
            break;
          case 'COOKIES_ACCEPTED':
            localStorage.setItem(LocalStorageType.CookieStatus, message);
            break;
          case 'CLOSE_COOKIE_NOTICE_BANNER':
            Cookies.set(CookieType.BlackHorseCookieConsent, 'true');
            break;
          case MessageCommands.FRAME_READY:
            if (m.origin === workflowOrigin.current) {
              send({
                type: Commands.OFFERS,
                data: encodeBase64({
                  offers: AppPropManager.offers,
                  origin: workflowOrigin.current,
                }),
              });
            }
            break;
          default:
            // PREQUALIFICATION_ACCEPT
            // PREQUALIFICATION_PARTNER
            console.error('Unhandled message:', message);
        }
      }
    },
    [isLegacyProducts, isPreCheckout]
  );

  const frame = useFrameMessaging(workflowOrigin, isLegacyProducts ? () => {} : onFrameReady, onFrameMessage);

  React.useEffect(() => {
    if (authCertificate?.expiresOn) {
      SessionExpirationAgent.startSessionTimer(authCertificate.expiresOn, () => {
        if (
          !actionPayloadRef.current ||
          actionPayloadRef.current.status !== ApplicationStatus.error ||
          (actionPayloadRef.current.status === ApplicationStatus.error &&
            actionPayloadRef.current.reason === 'Unauthorized')
        ) {
          apiClient.unsubscribe(listener);
          safeSetPage(Pages.error);
        }
      });
    }
  }, [apiClient, authCertificate, authToken, safeSetPage]);

  React.useEffect(() => {
    const userPrecheckoutWorkflowInProgress = PrecheckoutStateManager.getPrecheckoutStateByKey(
      'precheckoutWorkflowInProgress'
    );
    if (
      (!authToken || (initCertificate.getSplash(selectedProduct) && !AppPropManager.isCheckoutToken)) &&
      !isStandAlone &&
      !userPrecheckoutWorkflowInProgress
    ) {
      safeSetPage(Pages.preCheckout);
    } else if (isLegacyProducts) {
      if (availableProductNames.length === 1 && isRevolvingCreditProduct) {
        createApplication();
      } else {
        safeSetPage(Pages.preCheckout);
      }
    } else {
      createApplication();
    }

    return () => apiClient.unsubscribe(listener);
  }, [
    isStandAlone,
    isLegacyProducts,
    availableProductNames.length,
    isRevolvingCreditProduct,
    authToken,
    selectedProduct,
  ]);

  React.useEffect(() => {
    if (workflowType.current === WorkflowType.SWITCH) {
      apiClient.startPolling(submitAction.current, workflowPollInterval.current, authToken);
    }
    if (workflowType.current === WorkflowType.IMMEDIATE_REDIRECT) {
      window.location.href = workflowUrl.current;
    }
  }, [workflowType.current]);

  const startPreCheckoutWorkflow = ({ url, originUrl, type, schema }: StartPreCheckoutWorkflow): void => {
    initialWorkflowState.current = {
      url,
      originUrl,
      type,
      schema,
    } as InitialWorkflowState;

    workflowUrl.current = url;
    workflowOrigin.current = originUrl;

    if (type) workflowType.current = type;
    if (schema) workflowSchema.current = schema;

    setSchemaKey(Date.now());
    safeSetPage(Pages.workflow);
    if (precheckoutPersistance === 'enabled') {
      PrecheckoutStateManager.setPrecheckoutStateByKey('precheckoutWorkflowInProgress', true);
    }
  };

  const CurrentPage = React.useMemo(() => {
    if (page === Pages.preCheckout) {
      return (
        <SplashPreCheckout
          selectedProduct={selectedProduct}
          createApplication={createApplication}
          setSelectedProduct={setSelectedProduct}
          startPreCheckoutWorkflow={startPreCheckoutWorkflow}
          onClose={onClose}
          initCertificate={initCertificate}
          availableProductNames={availableProductNames}
          basketValue={basketValue}
          isLegacyProducts={isLegacyProducts}
        />
      );
    }

    if (page === Pages.workflow) {
      if (workflowType.current === WorkflowType.IMMEDIATE_REDIRECT) {
        return (
          <>
            <Loader isActive selectedProduct={selectedProduct} />
            {CurrentPage}
          </>
        );
      }
      return (
        <>
          <FadeyDiv
            as={Iframe}
            isActive
            hidden={!!promotional}
            id="workflow-iframe"
            title={workflowSchema.current?.title ?? workflowSchema.current?.meta?.theme?.title}
            key={schemaKey}
            src={workflowUrl.current}
            ref={frame}
            data-testid="monthly-frame"
          />
          {environmentVariables.environmentName !== 'production' && environmentVariables.mockHeaders && (
            <MockHeaderPicker mockHeaders={environmentVariables.mockHeaders} />
          )}
        </>
      );
    }

    if (page === Pages.success) {
      return <SuccessPage selectedProduct={selectedProduct} onClose={onClose} />;
    }

    if (page === Pages.fail) {
      return <FailPage selectedProduct={selectedProduct} onClose={onClose} />;
    }

    if (page === Pages.error || error?.length > 0) {
      switch (selectedProduct) {
        case ProductNames.INSTALMENT:
        case ProductNames.DEKO_MONTHLY:
        case ProductNames.SPLIT:
        case ProductNames.MULTI_LENDER:
        case ProductNames.GENERIC:
          return <ErrorPage token={authToken || initToken} onClose={onClose} error={error} />;
        case ProductNames.BLACKHORSE_FLEXPAY:
        case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
          return (
            <BlackhorseErrorScreen
              isSessionExpired={SessionExpirationAgent.hasExpired}
              isStandAlone={isStandAlone}
              onClose={onClose}
            />
          );
        case ProductNames.NEWPAY:
        case ProductNames.NEWPAY_STANDALONE:
          return (
            <NewpayErrorScreen
              isSessionExpired={SessionExpirationAgent.hasExpired}
              isStandAlone={isStandAlone}
              onClose={onClose}
            />
          );
        case ProductNames.REVOLVING_CREDIT:
          return AppPropManager.isProductVersionV2 ? (
            <NewpayErrorScreen
              isSessionExpired={SessionExpirationAgent.hasExpired}
              isStandAlone={isStandAlone}
              onClose={onClose}
            />
          ) : (
            <ErrorPage token={authToken || initToken} onClose={onClose} error={error} />
          );
        default:
          break;
      }
    }

    return null;
  }, [
    page,
    selectedProduct,
    authToken,
    initToken,
    error,
    promotional,
    schemaKey,
    isLegacyProducts,
    basketValue,
    availableProductNames.length,
    initCertificate,
  ]);

  const isSplashWithProduct = React.useMemo(() => promotional > 0 && page !== Pages.loading, [promotional, page]);

  return (
    <>
      <Loader isActive={page === Pages.loading} selectedProduct={selectedProduct} />
      {isSplashWithProduct && (
        <SplashWithProduct
          initCertificate={initCertificate}
          productAmount={promotional}
          environmentVariables={environmentVariables}
          selectedProduct={selectedProduct}
        />
      )}
      {CurrentPage}
    </>
  );
};

export default WorkflowStep;
