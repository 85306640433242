import { JWTLoader } from '@app-types';

import { HandledErrors, UnhandledErrors } from '../errors';

export enum AsyncAdaptorStatus {
  success = 'success',
  fail = 'fail',
}

export default async function asyncAdapter(
  tokenLoader: JWTLoader,
  errorMessage?: HandledErrors | UnhandledErrors
): Promise<string> {
  try {
    return await tokenLoader();
  } catch (e) {
    throw new Error(errorMessage || e.message);
  }
}
