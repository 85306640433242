import { Restriction } from 'components-typescript-react';

import { ProductNames, SchemaObject } from '@app-types';
import { WorkflowType } from '@components/WorkflowStep/types';
import { CACError, DekoErrorInfo } from '@utils/Errors/types';

export enum Currency {
  GBP = 'GBP',
}

export enum OfferSource {
  Promotional = 'Promotional',
  Product = 'Product',
}

export interface DekoMonthlyOfferFields {
  subsidyLower: number;
  subsidyUpper: number;
  p4lMinFee: number;
  lenderMinFee: number;
  lMin: number;
  lMax: number;
  factor: number;
  fees: number;
  roundRepayments: string;
  repaymentsSpread: string;
  loan_type: string;
}

export interface LenderOfferFields {
  productCode: string;
  creditProductType: string;
  creditProductCollectionType: string;
  apr: number;
  monthlyRate: number;
  minDeferredMonths: string;
  maxDeferredMonths: string;
  minTermMonths: string;
  maxTermMonths: string;
  minDeposit: number;
  minPurchaseAmount: number;
}

export interface OffersIllustration {
  monthlyPayment: {
    value: number;
    currencyCode: Currency;
  };
  interestToPay: {
    value: number | string;
    currencyCode: Currency;
  };
  totalRepayable: {
    value: number | string;
    currencyCode: Currency;
  };
}

export interface Offers {
  monthlyPayment?: number;
  value?: number;
  description: string;
  term: number;
  apr: number;
  sar?: number;
  promotional?: boolean;
  special?: boolean | number;
  id?: string;
  lender?: string;
  lenderOfferFields?: LenderOfferFields | DekoMonthlyOfferFields;
  restrictions?: Restriction[];
  offerSource?: OfferSource;
  illustration?: OffersIllustration;
  _links?: {
    self: {
      href: string;
    };
  };
}

interface Links {
  self: {
    href: string;
  };
  'product:apply': {
    href: string;
  };
}

export interface InitPayloadSplash {
  stepName: string;
  asset: string;
  type: WorkflowType;
  schema: SchemaObject;
}

interface Product {
  offers: Offers[];
  _links: Links;
  splash?: InitPayloadSplash;
}

export interface Products {
  [ProductNames.SPLIT]?: {
    _links: Links;
  };
  [ProductNames.REVOLVING_CREDIT]?: Product;
  [ProductNames.INSTALMENT]?: Product;
  [ProductNames.BLACKHORSE_FLEXPAY]?: Product;
  [ProductNames.NEWPAY]?: Product;
}

export interface ValidationErrors {
  [ProductNames.SPLIT]?: {
    [CACError.currencyNotSupported]?: {
      supportedCurrencies: Currency[];
    };
    [CACError.amountTooLow]?: {
      minAmount: number;
    };
    [CACError.amountTooHigh]?: {
      maxAmount: number;
    };
  };
  [ProductNames.REVOLVING_CREDIT]?: {
    [CACError.currencyNotSupported]?: {
      supportedCurrencies: Currency[];
    };
    [CACError.amountTooHigh]?: {
      maxAmount: number;
    };
    [CACError.basketItemNotSupported]?: {
      item: string;
    };
  };
}

export interface Basket {
  currency: 'GBP';
  total: string;
  items: {
    description: string;
    quantity: number;
    price: string;
    type: 'sku';
    imageUri: string;
    itemUri: string;
  }[];
}

export interface CheckoutAuthCertificateLens {
  hasAvailableProducts: boolean;
  availableProductNames: ProductNames[];
  availableProductName: ProductNames;
  hasBasket: boolean;
  basket: Basket;
  ineligibleProducts: DekoErrorInfo[] | undefined;
  basketValue: number;
  getApplyUrl: (product: ProductNames) => string;
  expiresOn: Date | undefined;
  isExpired: boolean;
  customerInfo: CustomerInfo;
}

export interface BillingAddress {
  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  country: string;
}

interface ShippingAddress {
  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  country: 'GB';
}

export interface CustomerInfo {
  billingAddress: BillingAddress;
  shippingAddress?: ShippingAddress;
  phone: string;
  email: string;
  birthDate: string;
}

export interface CACPayload {
  iat: number; // initialized at
  exp: number; // expire at
  iss: 'api.deko.finance' | 'api.dekopay.com'; // issuer
  aud: 'api.deko.finance' | 'api.dekopay.com'; // audience
  jti: string; // jwt id
  sub: string; // subject
  name: string;
  claimKey: string;
  [key: `${string}/checkout`]: {
    customer: CustomerInfo;
    uniqueReference: string; // 'some-unique-reference-from-the-ecommerce-platform'
    basket: Basket;
  };
  [key: `${string}/product`]: Products;
  [key: `${string}/product/ineligible`]: ValidationErrors;
}
