import { CACPayload, CustomerInfo } from '@utils/CheckoutAuthCertificate/types';
import { InitPayload } from '@utils/InitCertificate/types';

const customerInfo = (payload: CACPayload | InitPayload): CustomerInfo | undefined => {
  if (!payload[`${payload.claimKey}/checkout`] || !payload[`${payload.claimKey}/checkout`].customer) {
    return;
  }

  return {
    billingAddress: payload[`${payload.claimKey}/checkout`].customer.billingAddress || '',
    phone: payload[`${payload.claimKey}/checkout`].customer.phone || '',
    email: payload[`${payload.claimKey}/checkout`].customer.email || '',
    birthDate: payload[`${payload.claimKey}/checkout`].customer.birthDate || '',
  };
};

export default customerInfo;
