import { Application, CreateApplicationStatus } from '@app-types';

export type PrecheckoutPersistance = {
  initToken: string | null;
  application: Application;
  precheckoutWorkflowInProgress: boolean;
};

export const dummyPrecheckoutPersistance: PrecheckoutPersistance = {
  initToken: '',
  application: {
    status: CreateApplicationStatus.failure,
  },
  precheckoutWorkflowInProgress: false,
} as const;

export type PrecheckoutPersistanceKeys = keyof PrecheckoutPersistance;
