import { type InitToken, ProductNames } from '@app-types';
import getFirstAvailableProduct from '@utils/getAvailableProduct';
import type { InitCertificateLens, InitPayload } from '@utils/InitCertificate/types';
import decode from '@utils/Token/decode';
import getApplyUrlForProduct from '@utils/Token/getApplyUrlForProduct';
import getProductNames from '@utils/Token/getProductNames';
import getProductOffers from '@utils/Token/getProductOffers';

const InitCertificate = (token: InitToken): InitCertificateLens => {
  try {
    const payload = decode<InitPayload>(token);
    const productNames = getProductNames(payload);
    const offers = getProductOffers(payload);

    return {
      get retailerName() {
        return payload.name;
      },
      get availableProductNames() {
        return productNames;
      },
      get availableProductName() {
        return getFirstAvailableProduct(this.availableProductNames);
      },
      get hasAvailableProducts() {
        return productNames.length > 0;
      },
      get offers() {
        return offers.length > 0 ? offers : this.getSplash(this.availableProductName)?.schema?.meta?.offers || [];
      },
      get retailerId() {
        return payload.sub;
      },
      get repExamples() {
        return payload['https://www.dekopay.com/terms-of-service']?.repExamples;
      },
      get env() {
        return payload.env;
      },
      get authToken() {
        return payload.app;
      },
      get isStandAlone() {
        return payload?.standalone;
      },
      get eventApiUrl() {
        return payload.aud;
      },
      get expiry(): Date {
        return new Date(payload.exp * 1000);
      },
      get initToken() {
        return token;
      },
      getSplash(product: ProductNames) {
        const res = payload[`${payload.claimKey}/product`][product]?.schema;
        return res;
      },
      productOffers(product: ProductNames) {
        return offers.length > 0 ? offers : this.getSplash(product)?.schema?.meta?.offers || [];
      },
      getProductUrl: (product) => getApplyUrlForProduct(payload, product),
    };
  } catch (e) {
    throw new Error('Invalid init token 😢');
  }
};

export default InitCertificate;
