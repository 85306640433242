import { Basket, CACPayload } from '@utils/CheckoutAuthCertificate/types';
import { InitPayload } from '@utils/InitCertificate/types';

const basket = (payload: CACPayload | InitPayload): Basket => {
  try {
    return payload[`${payload.claimKey}/checkout`].basket;
  } catch {
    return undefined;
  }
};

export default basket;
