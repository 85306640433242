import styled, { keyframes } from 'styled-components';

export const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LoaderContainer = styled.div`
  animation: ${fadein} 0.7s ease-in-out;
`;
