const penceToDecimal = (penceValue: number) => {
  if (penceValue < 0) {
    throw new Error('cannot convert negative value');
  }
  if (penceValue % 1 !== 0) {
    throw new Error('cannot convert decimal value');
  }

  return parseFloat((penceValue / 100).toFixed(2));
};

export default penceToDecimal;
