import { Organisms, Templates } from 'components-typescript-react';
import { FormBuilderProps } from 'components-typescript-react/dist/components/Workflow/FormBuilder/types';
import React from 'react';

import FlexpayErrorSchema from '../../schemas/blackhorse-flexpay-error-page.json';
import FlexPaySessionExpiredSchema from '../../schemas/flexpay-session-expired-page.json';
import FlexPaySessionExpiredStandAloneSchema from '../../schemas/flexpay-session-expired-standalone-page.json';

const BlackhorseErrorPage = Organisms.FlexPayGenericError as React.FC<FormBuilderProps>;
const FlexPaySessionExpiredPage = Templates.FlexPaySessionExpired as React.FC<FormBuilderProps>;

interface BlackhorseErrorScreenProps {
  isSessionExpired: boolean;
  isStandAlone: boolean;
  onClose: () => void;
}

const BlackhorseErrorScreen: React.FC<BlackhorseErrorScreenProps> = ({ isSessionExpired, isStandAlone, onClose }) => {
  const schema = !isStandAlone ? FlexPaySessionExpiredSchema : FlexPaySessionExpiredStandAloneSchema;
  let error = <BlackhorseErrorPage schema={FlexpayErrorSchema as any} onClose={onClose} />;

  const handleClose = () => {
    onClose();
    if (isSessionExpired) {
      window.location.reload();
    }
  };

  if (isSessionExpired) {
    error = <FlexPaySessionExpiredPage schema={schema as any} onClose={handleClose} />;
  }
  return error;
};

export default BlackhorseErrorScreen;
