import React, { useEffect, useState } from 'react';

import { MockApiAdapter, PublicApiAdapter } from '@utils';

import { ApiClientContext, context, setApiAdapter } from '../instances';
import { AdapterVersion } from '../types';

interface ApiClientProviderProps {
  children: React.ReactNode;
  adapter?: AdapterVersion;
}

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children, adapter = AdapterVersion.live }) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    switch (adapter) {
      case AdapterVersion.live:
        setApiAdapter(new PublicApiAdapter());
        setIsReady(true);
        break;
      case AdapterVersion.mock:
      default:
        setApiAdapter(new MockApiAdapter());
        setIsReady(true);
    }
  }, [adapter]);

  return <ApiClientContext.Provider value={context}>{isReady ? children : null}</ApiClientContext.Provider>;
};

export default ApiClientProvider;
